import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"
import TextInput from "../Forms/TextInput"
import DateInput from "../Forms/DateInput"
import BtnForms from "../Buttons/BtnForms"
import CheckBox from "../Forms/CheckBox"
import TabBar from "../Auth/TabBar"
import GenderSelection from "../Forms/GenderSelection"
import { useTranslation } from "react-i18next"
import "react-phone-input-2/lib/style.css"
import PhoneInput from "react-phone-input-2"
import { checkValidPhone } from "../../helpers/Phone"
import cx from 'classnames';

function Register(props) {
  const { changeTab, onSubmitForm } = props
  const [phoneNumberCountry, setPhoneNumberCountry] = useState("be");
  const { t } = useTranslation()

  const logAnalytics = (values) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "play_nostalgie_register",
      login_registration_step: "complete data",
      login_newsletter: values?.newsletter,
      login_actions: values?.promo,
      login_gender: values.gender
    })
  }

  const registerSchema = Yup.object().shape({
    firstName: Yup.string().required(t("validation.firstNameRequired")),
    lastName: Yup.string().required(t("validation.lastNameRequired")),
    zipCode: Yup.string(),
    country: Yup.string().when("zipCode", {
      is: val => val !== undefined,
      then: Yup.string().required(t("validation.countryRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    email: Yup.string()
      .email(t("validation.emailValid"))
      .required(t("validation.emailRequired")),
    phone: Yup.string()
      .test(
        "Phone Test",
        t("validation.phoneInvalid"),
        value => (value > 0 ? checkValidPhone(value, phoneNumberCountry) : true)
      )
      .required(t("validation.phoneRequired")),
    dateOfBirth: Yup.string()
      .min(10, t("validation.dateOfBirthValid"))
      .required(t("validation.dateOfBirthRequired")),
    gender: Yup.string()
      .oneOf(["male", "female", "other"], t("validation.genderRequired"))
      .required(t("validation.genderRequired")),
  })

  return (
    <div className="register">
      <TabBar onClickLogin={changeTab} activeTab={"register"} />
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          dateOfBirth: "",
          gender: "male",
          phone: "",
          zipCode: "",
          country: "Belgium",
          newsletter: false,
          promo: false,
        }}
        validationSchema={registerSchema}
        onSubmit={(values, { setSubmitting }) => {
          let terms = {
            items: [
              {
                id: process.env.VERIFIED_TERMS_ID,
                source: "website",
              },
            ],
          }
          if (values.promo) {
            terms.items.push({
              id: process.env.THIRD_PARTY_OPT_IN,
              source: "website",
            })
          }
          if (values.newsletter) {
            terms.items.push({
              id: process.env.NEWSLETTER_ID,
              source: "website",
            })
          }
          setSubmitting(false)
          logAnalytics(values)
          onSubmitForm({
            ...values,
            ...{ terms: terms },
            ...{ country: values.country, zipCode: values.zipCode },
          })
        }}
      >
        {({ handleSubmit, isSubmitting, errors, setFieldValue, values }) => (
          <form className={"form"} onSubmit={handleSubmit}>
            <TextInput
              name="firstName"
              type="text"
              placeholder={t("forms.firstName")}
            />
            <TextInput
              name="lastName"
              type="text"
              placeholder={t("forms.lastName")}
            />
            <TextInput
              name="email"
              type="email"
              placeholder={t("forms.email")}
            />
            <TextInput name="zipCode" type="text" placeholder="Postcode" />
            <div className={cx("field", errors.phone && 'error')}>
              <PhoneInput
                name="phone"
                value={values.phone}
                onChange={(value, country) => {
                  setFieldValue('phone', value ? value : "");
                  setPhoneNumberCountry(country.countryCode);
                }}
                placeholder={t("forms.phone")}
                country={phoneNumberCountry}
                inputClass="field--input"
                enableAreaCodeStretch
              />
              {errors.phone ? (
                <div className="field--error">{errors.phone}</div>
              ) : null}
            </div>
            <DateInput
              name="dateOfBirth"
              type="text"
              placeholder={t("forms.dateOfBirth")}
            />
            <GenderSelection name="gender" label={t("forms.gender")} />
            <CheckBox name="newsletter" value="newsletter">
              {t("forms.newsletter")}
            </CheckBox>
            <CheckBox name="promo" value="promo">
              {t("forms.promo")}
            </CheckBox>
            <BtnForms
              large
              primary
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t("register.register")}
            </BtnForms>
          </form>
        )}
      </Formik>
    </div>
  )
}

Register.propTypes = {
  changeTab: PropTypes.func,
  onSubmitForm: PropTypes.func,
}

export default Register
